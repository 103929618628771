<template>
  <el-input
    :value="value"
    placeholder="请输入手机号码"
    type="text"
    class="phone"
    autocomplete="off"
    @input="inputChange"
  >
    <el-select
      slot="prepend"
      :value="phoneType"
      class="phone-prepend"
      @input="$emit('update:phoneType',$event)"
    >
      <el-option
        label="+86"
        value="1"
      />
    </el-select>
  </el-input>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },
    phoneType: {
      type: [String, Number]
    }
  },
  methods: {
    inputChange(value) {
      const phone = value.replace(/\s/g, '').replace(/(\d{3})(\d{0,4})(\d{0,4})(.*)/, (a, b, c, d) => {
        const len = a.length;
        if (len <= 3) {
          return b;
        }
        if (len <= 7) {
          return `${b} ${c}`;
        }
        return `${b} ${c} ${d}`;
      });
      this.$emit('input', phone);
    }
  },
};
</script>

<style lang="less" scoped>
.phone{
  position: relative;
   /deep/ .el-input__inner{
    padding-left: 78px;
  }
}

.phone-prepend{
  margin: 0;
  /deep/ .el-input__inner{
    padding-left: 0;
  }
}

/deep/.el-input-group__prepend{
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  padding: 0;
  width: 40px;
  width: 62px;
  padding-left: 16px;
  border: none;
  background: transparent;
}
</style>
